import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b1535c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "productcard"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 4,
  class: "messageActions"
}
const _hoisted_8 = {
  key: 5,
  style: {"color":"black","width":"100%","display":"flex","justify-content":"space-between"}
}
const _hoisted_9 = {
  key: 6,
  class: "coupon"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductCardV2 = _resolveComponent("ProductCardV2")!
  const _component_ProductCard = _resolveComponent("ProductCard")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["singleMessage", { sendedFromWilli: _ctx.isMessageSenderWilli, productCard: _ctx.message.Type === 'WINECARD' || _ctx.message.Type === 'OFFERCARD', coupon: _ctx.message.Type === 'COUPON' }])
  }, [
    (_ctx.isMessageSenderWilli &&
			(_ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY')
			)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["avatarContainer", { center: true, border: _ctx.chatbotTexts?.showIconBorder === 'true' }]),
          style: _normalizeStyle({ backgroundColor: _ctx.company.FrontendDesign?.PrimaryColor })
        }, [
          _createElementVNode("img", { src: _ctx.avatar }, null, 8, _hoisted_1)
        ], 6))
      : _createCommentVNode("", true),
    (_ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["message", {
			sendedFromWilli: _ctx.isMessageSenderWilli,
			normal: _ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY',
		}])
        }, _toDisplayString(_ctx.message.Text?.replaceAll("<br/>", "")), 3))
      : (_ctx.message.Type === 'WINECARD' || _ctx.message.Type === 'OFFERCARD')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.company.FrontendDesign?.ProductCardVersion === 'SECOND')
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredProductResults?.slice(0, 5), (product, index) => {
                  return (_openBlock(), _createBlock(_component_ProductCardV2, {
                    key: product.Name,
                    company: _ctx.company,
                    "is-card-flipped": index === 0 && _ctx.firstV2CardFlipped,
                    product: (product ?? {} as Product),
                    stylingVariant: _ctx.stylingVariant,
                    onProductClicked: _ctx.productClicked,
                    onProductFeedbackCauseSelected: _ctx.productFeedbackCauseSelected,
                    showOfferMode: _ctx.message.Type === 'OFFERCARD'
                  }, null, 8, ["company", "is-card-flipped", "product", "stylingVariant", "onProductClicked", "onProductFeedbackCauseSelected", "showOfferMode"]))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredProductResults?.slice(0, 5), (product) => {
                  return (_openBlock(), _createBlock(_component_ProductCard, {
                    key: product.Name,
                    company: _ctx.company,
                    product: (product ?? {} as Product),
                    stylingVariant: _ctx.stylingVariant,
                    onProductClicked: _ctx.productClicked,
                    onProductFeedbackCauseSelected: _ctx.productFeedbackCauseSelected,
                    showOfferMode: _ctx.message.Type === 'OFFERCARD'
                  }, null, 8, ["company", "product", "stylingVariant", "onProductClicked", "onProductFeedbackCauseSelected", "showOfferMode"]))
                }), 128))
          ]))
        : (_ctx.message.Type === 'ANSWERSELECTION')
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(["messageActions answerSelection", { 'row': (_ctx.message.AnswerSelection?.length && _ctx.message.AnswerSelection?.length > 5) }])
            }, [
              (_ctx.message.QuestionType === 'MultipleChipSelection')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message.AnswerSelection, (answer) => {
                      return (_openBlock(), _createElementBlock("button", {
                        class: _normalizeClass(["secondary", { 'selected': answer.selected }]),
                        key: answer.Id,
                        onClick: ($event: any) => (_ctx.toggleAnswerSelection(answer))
                      }, _toDisplayString(answer.Text), 11, _hoisted_4))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message.AnswerSelection, (answer) => {
                      return (_openBlock(), _createElementBlock("button", {
                        class: _normalizeClass(["secondary", answer.selected]),
                        key: answer.Id,
                        onClick: ($event: any) => (_ctx.singleActionClicked(answer))
                      }, _toDisplayString(answer.Text), 11, _hoisted_6))
                    }), 128))
                  ])),
              (_ctx.message.QuestionType === 'MultipleChipSelection')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "red-button",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendMultipleAnswers && _ctx.sendMultipleAnswers(...args)))
                  }, "Weiter"))
                : _createCommentVNode("", true)
            ], 2))
          : (_ctx.message.Type !== 'COUPON')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.message.PrimaryFunction && _ctx.message.PrimaryFunction())),
                  class: _normalizeClass(_ctx.message.PrimaryButtonStyle)
                }, _toDisplayString(_ctx.message.Text), 3),
                (_ctx.message.Type === 'TWOBUTTONS')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: _normalizeClass(["secondary", _ctx.message.SecondaryButtonStyle]),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.message.SecondaryFunction && _ctx.message.SecondaryFunction()))
                    }, _toDisplayString(_ctx.message.TextSecondary), 3))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
    (_ctx.message.Type === 'WINECARD' || _ctx.message.Type === 'OFFERCARD')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_Arrow, {
            class: "navigationArrow",
            style: {"transform":"rotate(180deg)"},
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollProductCards(-1)))
          }),
          _createVNode(_component_Arrow, {
            class: "navigationArrow",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.scrollProductCards(1)))
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.message.Type === 'COUPON')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_ctx.company.CompanyQuizConfig.CouponImageUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.openCouponUrl && _ctx.openCouponUrl(...args))),
                src: _ctx.company.CompanyQuizConfig.CouponImageUrl,
                alt: "Coupon QR Code",
                style: {"max-height":"200px","width":"auto","max-width":"100%"}
              }, null, 8, _hoisted_10))
            : (_ctx.company.CompanyQuizConfig.CouponUrl)
              ? (_openBlock(), _createBlock(_component_qrcode_vue, {
                  key: 1,
                  class: "qrCode",
                  value: _ctx.company.CompanyQuizConfig.CouponUrl,
                  size: 150,
                  level: "H",
                  foreground: _ctx.company.FrontendDesign?.PrimaryColor
                }, null, 8, ["value", "foreground"]))
              : _createCommentVNode("", true),
          _createElementVNode("a", {
            href: _ctx.company.CompanyQuizConfig.CouponUrl,
            target: "_blank"
          }, _toDisplayString(_ctx.company.CompanyQuizConfig.CouponUrl), 9, _hoisted_11)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}