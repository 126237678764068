import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ae0211f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ap-productfinder" }
const _hoisted_2 = {
  key: 0,
  class: "tooltip"
}
const _hoisted_3 = { class: "featureActions" }
const _hoisted_4 = { class: "chatbotNew" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatbotWindowV3 = _resolveComponent("ChatbotWindowV3")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "infoBubble",
            class: _normalizeClass({focusMode: _ctx.focus})
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.showBubble)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (!_ctx.williTexts?.DenyText)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "infoBubbleClose",
                          onClick: _cache[0] || (_cache[0] = 
							() => {
								_ctx.showBubble = false;
								_ctx.focus = false;
							}
						)
                        }, " X "))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.williTexts?.MainText) + " ", 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", {
                        class: "deny",
                        onClick: _cache[1] || (_cache[1] = 
								() => {
									_ctx.showBubble = false;
									_ctx.focus = false;
								}
							)
                      }, _toDisplayString(_ctx.williTexts?.DenyText), 1),
                      _createElementVNode("div", {
                        class: "confirm",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.company.Match ? _ctx.company.openResult() : _ctx.company?.startQuiz()))
                      }, _toDisplayString(_ctx.williTexts?.ConfirmText), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ChatbotWindowV3, {
                company: _ctx.company,
                onStartQuiz: _cache[3] || (_cache[3] = ($event: any) => (_ctx.company.startQuiz())),
                onHardStartQuiz: _cache[4] || (_cache[4] = (quizId) => _ctx.company.startQuiz(true, quizId)),
                onOpenResult: _cache[5] || (_cache[5] = ($event: any) => (_ctx.company.openResult()))
              }, null, 8, ["company"])
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}