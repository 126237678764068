import {
	BottleClickAction,
	NavigationMode,
	ResultButtonAction,
} from "@/entities/enums/QuizConfigEnums";
import { ICompanyQuizConfigResponse } from "@/interfaces/api/responses/ICompanyQuizConfigResponse";

class ChatbotMode {
	MainText?: string;
	DenyText?: string;
	ConfirmText?: string;

	SetFocusAutomatically?: boolean;
	SetNoticeAutomatically?: boolean;

	QuizOnlyMode?: boolean;

	constructor(chatbotMode: ChatbotMode) {
		this.MainText = chatbotMode.MainText;
		this.DenyText = chatbotMode.DenyText;
		this.ConfirmText = chatbotMode.ConfirmText;
		this.SetFocusAutomatically = chatbotMode.SetFocusAutomatically;
		this.SetNoticeAutomatically = chatbotMode.SetNoticeAutomatically;
		this.QuizOnlyMode = chatbotMode.QuizOnlyMode;
	}

	static createFromApiResponse(response: ICompanyQuizConfigResponse): ChatbotMode {
		return new ChatbotMode({
			MainText:
				response.chatbotModeText ??
				"Hey, ich bin Willi Wein. Lass uns deinen neuen Lieblingsprodukt finden!",
			DenyText: response.chatbotModeDeny ?? "Nein danke.",
			ConfirmText: response.chatbotModeAnswer ?? "Ja, Gerne!",
			SetFocusAutomatically: response.chatbotModeFocus === "true",
			SetNoticeAutomatically: response.chatbotModeNotice === "true",
			QuizOnlyMode: response.QuizOnlyMode === "true",
		});
	}
}

export class CompanyQuizConfig {
	ResultButtonAction?: ResultButtonAction;
	Languages?: string[];
	ShowSizeIndicator?: boolean;
	ShowRestartButton?: boolean;
	ShowOnlyVerifiedProducts?: boolean;
	showPrinterOption?: boolean;
	showPartnerMatching?: boolean;
	BottleClickAction?: BottleClickAction;
	QrCodeMode?: boolean;
	UrlToBlockFeatureModeOn?: string;
	TransparentMode?: boolean;

	OpenEmailDialogAfter?: number;
	EmailDialogText?: string;

	ChatbotMode?: ChatbotMode;

	DisableFeedbackQuestion?: boolean;
	DisableFeedbackQuestionOnMobile?: boolean;
	SizeIndicatorGlassText?: string;
	SizeIndicatorBottleText?: string;

	ShopifyShopProductPrefix?: string;

	QrCode?: string;

	ExtraChatBotQuiz?: number;
	ExpertQuiz?: number;
	QuizCountCorrection?: number;
	UseFormalSalutation?: boolean;
	BTGMode?: boolean;
	DarkMode?: boolean;

	NavigationMode?: NavigationMode;

	FullscreenMode?: boolean;

	AdvancedProductResultFiltering?: boolean;

	AutoOpenUrls?: string[];
	HideBotOnClose?: boolean;

	SetMatchToUrl?: boolean;

	TasteOffersActive?: boolean;

	CouponUrl?: string;
	CouponImageUrl?: string;
	CouponUrlInfo?: string;
	CouponUrlInfo2?: string;
	CurrencySymbol?: string;
	ShowMatchingPercentage?: boolean;

	EventMode?: boolean;
	DemoLocation?: string;

	ProductCardActionText?: string;

	PlayNotificationOnce?: boolean;
	ShowGrapeResultView?: boolean;

	MatchingCallbackActive?: boolean;

	RedirectWithWarning?: boolean;
	QuizOnlyMode?: boolean;

	SuperSearchActive?: boolean;

	constructor(companyQuizConfig: CompanyQuizConfig) {
		// TODO: is something like this possible?
		// this = {...companyQuizConfig};

		this.ResultButtonAction = companyQuizConfig.ResultButtonAction;
		this.Languages = companyQuizConfig.Languages;
		this.ShowSizeIndicator = companyQuizConfig.ShowSizeIndicator;
		this.ShowRestartButton = companyQuizConfig.ShowRestartButton;
		this.ShowOnlyVerifiedProducts = companyQuizConfig.ShowOnlyVerifiedProducts;
		this.showPrinterOption = companyQuizConfig.showPrinterOption;
		this.BottleClickAction = companyQuizConfig.BottleClickAction;
		this.QrCodeMode = companyQuizConfig.QrCodeMode;
		this.ChatbotMode = companyQuizConfig.ChatbotMode;
		this.DisableFeedbackQuestion = companyQuizConfig.DisableFeedbackQuestion;
		this.DisableFeedbackQuestionOnMobile = companyQuizConfig.DisableFeedbackQuestionOnMobile;
		this.SizeIndicatorGlassText = companyQuizConfig.SizeIndicatorGlassText;
		this.SizeIndicatorBottleText = companyQuizConfig.SizeIndicatorBottleText;
		this.ShopifyShopProductPrefix = companyQuizConfig.ShopifyShopProductPrefix;
		this.UrlToBlockFeatureModeOn = companyQuizConfig.UrlToBlockFeatureModeOn;
		this.TransparentMode = companyQuizConfig.TransparentMode;
		this.OpenEmailDialogAfter = companyQuizConfig.OpenEmailDialogAfter;
		this.EmailDialogText = companyQuizConfig.EmailDialogText;
		this.QrCode = companyQuizConfig.QrCode;
		this.showPartnerMatching = companyQuizConfig.showPartnerMatching;
		this.ExtraChatBotQuiz = companyQuizConfig.ExtraChatBotQuiz;
		this.QuizCountCorrection = companyQuizConfig.QuizCountCorrection;
		this.UseFormalSalutation = companyQuizConfig.UseFormalSalutation;
		this.BTGMode = companyQuizConfig.BTGMode;
		this.DarkMode = companyQuizConfig.DarkMode;
		this.NavigationMode = companyQuizConfig.NavigationMode;
		this.FullscreenMode = companyQuizConfig.FullscreenMode;
		this.AdvancedProductResultFiltering = companyQuizConfig.AdvancedProductResultFiltering;
		this.AutoOpenUrls = companyQuizConfig.AutoOpenUrls;
		this.HideBotOnClose = companyQuizConfig.HideBotOnClose;
		this.SetMatchToUrl = companyQuizConfig.SetMatchToUrl;
		this.TasteOffersActive = companyQuizConfig.TasteOffersActive;
		this.CouponUrl = companyQuizConfig.CouponUrl;
		this.CouponUrlInfo = companyQuizConfig.CouponUrlInfo;
		this.CouponImageUrl = companyQuizConfig.CouponImageUrl;
		this.CouponUrlInfo2 = companyQuizConfig.CouponUrlInfo2;
		this.CurrencySymbol = companyQuizConfig.CurrencySymbol;
		this.ShowMatchingPercentage = companyQuizConfig.ShowMatchingPercentage;
		this.EventMode = companyQuizConfig.EventMode;
		this.DemoLocation = companyQuizConfig.DemoLocation;
		this.ProductCardActionText = companyQuizConfig.ProductCardActionText;
		this.PlayNotificationOnce = companyQuizConfig.PlayNotificationOnce;
		this.ShowGrapeResultView = companyQuizConfig.ShowGrapeResultView;
		this.MatchingCallbackActive = companyQuizConfig.MatchingCallbackActive;

		this.DarkMode = companyQuizConfig.DarkMode;
		this.UseFormalSalutation = companyQuizConfig.UseFormalSalutation;
		this.RedirectWithWarning = companyQuizConfig.RedirectWithWarning;
		this.FullscreenMode = companyQuizConfig.FullscreenMode;
		this.QuizOnlyMode = companyQuizConfig.QuizOnlyMode;
		this.SuperSearchActive = companyQuizConfig.SuperSearchActive;
	}

	static createFromApiResponse(
		companyQuizConfigResponse: ICompanyQuizConfigResponse
	): CompanyQuizConfig {
		// Map the API Response to the CompanyQuizConfig

		let resultButtonAction: ResultButtonAction | undefined = undefined;

		if (companyQuizConfigResponse.resultButtonAction?.toUpperCase() === "NAVMAPMODE")
			resultButtonAction = ResultButtonAction.OPENNAVIGATIONMAP;
		else if (companyQuizConfigResponse.resultButtonAction)
			resultButtonAction =
				ResultButtonAction[companyQuizConfigResponse.resultButtonAction?.toUpperCase()];

		const newCompanyQuizConfig = {
			ResultButtonAction: resultButtonAction,
			Languages: companyQuizConfigResponse?.languages
				? companyQuizConfigResponse.languages.split(",")
				: undefined,
			ShowRestartButton: companyQuizConfigResponse.showRestartButton === "true",
			QrCodeMode: companyQuizConfigResponse.qrCodeMode === "true",
			ChatbotMode: ChatbotMode.createFromApiResponse(companyQuizConfigResponse),
			DisableFeedbackQuestion: companyQuizConfigResponse.disableFeedbackQuestion === "true",
			DisableFeedbackQuestionOnMobile:
				companyQuizConfigResponse.disableFeedbackQuestionMobile === "true",
			UrlToBlockFeatureModeOn: companyQuizConfigResponse.urlToBlockFeatureModeOn,
			TransparentMode: companyQuizConfigResponse.transparentMode === "true",
			QrCode: companyQuizConfigResponse.qrCode,
			ExtraChatBotQuiz: Number(companyQuizConfigResponse.extraChatBotQuiz),
			ExpertQuiz: Number(companyQuizConfigResponse.expertQuiz),
			QuizCountCorrection: Number(companyQuizConfigResponse.quizCountCorrection),

			UseFormalSalutation: companyQuizConfigResponse.useFormalSalutation === "true",
			DarkMode: companyQuizConfigResponse.darkMode === "true",
			NavigationMode: companyQuizConfigResponse?.navigationMode
				? NavigationMode[companyQuizConfigResponse?.navigationMode?.toUpperCase()]
				: NavigationMode.NORMAL,
			FullscreenMode: companyQuizConfigResponse.fullscreenMode === "true",
			AdvancedProductResultFiltering:
				companyQuizConfigResponse.advancedProductResultFiltering === "true",
			AutoOpenUrls: companyQuizConfigResponse.autoOpenUrls?.split(","),
			HideBotOnClose: companyQuizConfigResponse.hideBotOnClose === "true",
			SetMatchToUrl: companyQuizConfigResponse.setMatchToUrl === "true",
			CouponUrl: companyQuizConfigResponse.couponUrl,
			CouponImageUrl: companyQuizConfigResponse.couponImageUrl,
			CouponUrlInfo: companyQuizConfigResponse.couponUrlInfo,
			CouponUrlInfo2: companyQuizConfigResponse.couponUrlInfo2,
			CurrencySymbol: companyQuizConfigResponse.currencySymbol ?? "€",
			ShowMatchingPercentage: companyQuizConfigResponse.showMatchingPercentage !== "false",
			EventMode: companyQuizConfigResponse.eventMode === "true",
			ProductCardActionText: companyQuizConfigResponse.productCardActionText,
			RedirectWithWarning: companyQuizConfigResponse.RedirectWithWarning === "true",
			QuizOnlyMode: companyQuizConfigResponse.QuizOnlyMode === "true",
			SuperSearchActive: companyQuizConfigResponse.supersearchActive === "true",
		} as CompanyQuizConfig;

		if (
			newCompanyQuizConfig.ChatbotMode &&
			companyQuizConfigResponse.urlToBlockFeatureModeOn &&
			window.location.href.includes(companyQuizConfigResponse.urlToBlockFeatureModeOn)
		)
			newCompanyQuizConfig.ChatbotMode.QuizOnlyMode = false;

		return newCompanyQuizConfig;
	}
}
