import { ICompanyResponse } from "@/interfaces/api/responses/ICompanyResponse";
import Quiz from "@/entities/domain/Quiz/Quiz";
import { FrontendDesign } from "./FrontendDesign";
import { CompanyQuizConfig } from "./CompanyQuizConfig";
import QuizService from "@/services/QuizService";
import { Match } from "../Match/Match";
import { Question } from "../Quiz/Question";
import { Translations } from "../Translations";
import {FirstLevelSupport} from "../FirstLevelSupport/FirstLevelSupport";

import Router from "@/router";
import { Languages, ResultButtonAction } from "@/entities/enums/QuizConfigEnums";
import { QuestionType } from "@/entities/enums/QuizEnums";
import { Answer } from "../Quiz/Answer";
import { QuestionFrontendSetting } from "../Quiz/QuestionFrontendSetting";
import { AnswerForMatching } from "@/entities/api/AnswerForMatching";
import { Product } from "../Match/Product";

import AddToStyling from "@/utils/AddToStyling";
import axios from "axios";
import { OperationResult } from "../OperationResult";
import DisplayedTexts from "../DisplayedTexts";
import DispatchEvent from "@/utils/DispatchEvent";

export default class Company {
	private _homepageMode = false;
	private _selectedLanguage = Languages.DE;
	private _languageWasSelected = false;
	private _baseComponentTranslations = {} as Translations;
	private _isQuizClosed = true;
	private _isCompanyLoaded = false;
	private _isSavedSessionLoaded = false;
	private _autoRedirect = false;
	private _isEmailQuestionProvided = false;

	private _quiz = {} as Quiz;

	CompanyCode?: string;
	FrontendDesign?: FrontendDesign;
	CompanyQuizConfig: CompanyQuizConfig;
	FloorPlan?: string;
	Match?: Match;

	Token?: string;

	AutoRedirect: boolean;

	DisplayedTexts: DisplayedTexts;

	FirstLevelSupport?: FirstLevelSupport;

	constructor(company?: Company) {
		this.CompanyCode = company?.CompanyCode;
		this.FrontendDesign = company?.FrontendDesign ?? ({} as FrontendDesign);
		this.CompanyQuizConfig =
			company?.CompanyQuizConfig ?? ({} as CompanyQuizConfig);
		this.FloorPlan = company?.FloorPlan;
		this.Match = company?.Match;
		this.Token = company?.Token;
		this.AutoRedirect = company?.AutoRedirect ?? false;
		this.DisplayedTexts = new DisplayedTexts(this);
		this.FirstLevelSupport = company?.FirstLevelSupport;
	}

	static createFromApiResponse(
		response: ICompanyResponse,
		companyCode: string
	): Company {
		// TODO: add missing api mapper

		const newCompany = {
			CompanyCode: companyCode,
			Quiz: Quiz.createFromApiResponse(response.quiz),
			FrontendDesign:
				FrontendDesign.createFromApiResponse(response.frontend_design) ?? undefined,
			CompanyQuizConfig: CompanyQuizConfig.createFromApiResponse(response.config ?? {}),
			FloorPlan: response.floor_plan,
			Token: response.token,
			FirstLevelSupport: response.first_level_support ? FirstLevelSupport.createFromApiResponse(response.first_level_support) : undefined,
		} as Company;

		return newCompany;
	}

	async loadCompanyAsync(
		companyCode: string,
		hardReset = false,
		quizId: number | undefined = undefined
	): Promise<OperationResult<string>> {
		if (!companyCode)
			return new OperationResult<string>({
				Success: false,
				ErrorMessage: "No CompanyCode provided in Company-Loader",
			});

		if (this.Quiz && this.Quiz?.Questions && !hardReset)
			return new OperationResult<string>({
				Success: false,
				ErrorMessage: "Quizdata already loaded and no hard reset was requested",
			});

		if (hardReset) this.Quiz.QuestionIdHistory = [];

		if (
			["BESCHREIBUNG", "AUSZEICHNUNGEN", "LEBENSMITTELNACHWEIS"].some((keyword) =>
				localStorage
					.getItem("companyCode")
					?.toUpperCase()
					.includes(keyword.toUpperCase())
			)
		)
			localStorage.removeItem("companyCode");

		this.CompanyCode = companyCode;
		const companyCodeToLoadQuizFor: string = companyCode;

		window.localStorage.setItem("companyCode", companyCodeToLoadQuizFor); // Always save last loaded Quiz

		let quizResponse;
		try {
			quizResponse = await QuizService.getQuiz(companyCodeToLoadQuizFor, quizId);
		} catch (error) {
			console.error("Quiz could not be loaded");
			console.error({ error });
			return new OperationResult<string>({
				Success: false,
				ErrorMessage: "Error while loading Company from QuizService",
			});
		}

		if (!quizResponse.Success || !quizResponse)
			return new OperationResult<string>({
				Success: false,
				ErrorMessage:
					quizResponse.ErrorMessage ??
					"Error while loading Company from QuizService, no Company was returned",
			});

		const loadedCompany = Company.createFromApiResponse(
			quizResponse.Data,
			companyCodeToLoadQuizFor
		);

		if (
			// companyCodeToLoadQuizFor === "TW-HOME" ||
			loadedCompany.CompanyQuizConfig.TransparentMode
		)
			this._homepageMode = true;
		else this._homepageMode = false;

		this._isCompanyLoaded = true;

		loadedCompany.Quiz = Quiz.filterQuizForLanguage(
			loadedCompany.Quiz,
			this._selectedLanguage
		);

		if (quizResponse.Data.translations) {
			this._baseComponentTranslations = quizResponse.Data.translations;
			// this._baseComponentTranslations = Company.filterBasicComponentsForTranslation(
			// 	this._baseComponentTranslations,
			// 	this._selectedLanguage
			// );
		}

		this.Quiz = loadedCompany.Quiz;
		this.FrontendDesign = loadedCompany.FrontendDesign;
		this.CompanyQuizConfig = loadedCompany.CompanyQuizConfig;
		this.FloorPlan = loadedCompany.FloorPlan;
		this.Token = loadedCompany.Token;

		this.FirstLevelSupport = loadedCompany.FirstLevelSupport;

		this.loadImagesToCache(); // Preload Images for performance

		if(this.FrontendDesign?.PrimaryFontFamily)
		{
			AddToStyling(`
				@font-face {
					font-family: 'PrimaryFontFamily';
					src: url('${this.FrontendDesign?.PrimaryFontFamily}') format('opentype');
					font-weight: normal;
					font-style: normal;
			}`)
		}

		if(this.FrontendDesign?.SecondaryFontFamily)
		{
			AddToStyling(`
				@font-face {
					font-family: 'SecondaryFontFamily';
					src: url('${this.FrontendDesign?.SecondaryFontFamily}') format('opentype');
					font-weight: normal;
					font-style: normal;
			}`)
		}

		AddToStyling(`*{
			--primary-color: ${this.FrontendDesign?.PrimaryColor ?? "#3d3b8e"};
			--secondary-color: ${this.FrontendDesign?.SecondaryColor ?? "#65648a"};
			--background-color: ${this.FrontendDesign?.BackgroundColor ?? "#ffffff"};
			--secondary-color-light: ${this.FrontendDesign?.SecondaryLightColor ?? "#e1e1da"};
			--primary-font-color: ${this.FrontendDesign?.PrimaryFontColor ?? "#333333"};
			--secondary-font-color: ${this.FrontendDesign?.SecondaryFontColor ?? "#65648a"};
			--disabled-button-background-color:  ${
				this.FrontendDesign?.DisabledButtonBackgroundColor ?? "#ccc"
			};
			--disabled-button-font-color:  ${this.FrontendDesign?.DisabledButtonFontColor ?? "#fff"};
			--progress-bar-background-color:  ${this.FrontendDesign?.ProgressBarBackgroundColor ?? "#65648a"};
			--fancy-button-stop-color:  ${this.FrontendDesign?.FancyButtonWaveEndColor ?? "#65648a"};
			--stepless-slider-background-color:  ${
				this.FrontendDesign?.SteplessSliderBackgroundColor ?? "#65648a"
			};
			--primary-font-family: ${this.FrontendDesign?.PrimaryFontFamily ?? "Zilla Slab"};
			--secondary-font-family: ${this.FrontendDesign?.SecondaryFontFamily ?? "Open Sans"};
			--primary-font-weight: ${this.FrontendDesign?.PrimaryFontWeight ?? "bold"};
			--secondary-font-weight: ${this.FrontendDesign?.SecondaryFontWeight ?? "400"};
			--slider-thumb: ${
				this.FrontendDesign?.SliderThumbImage ??
				'url("~@/assets/img/slider_button.svg")'
			}";

			--wizard-first-color-wave: ${this.FrontendDesign?.ProductWizard?.FirstColorWave ?? "#1C1027"};
			--wizard-second-color-wave: ${this.FrontendDesign?.ProductWizard?.SecondColorWave ?? "#90173E"};
			--wizard-primary-color: ${this.FrontendDesign?.ProductWizard?.PrimaryColor ?? "#7B163A"};
			--wizard-secondary-color: ${this.FrontendDesign?.ProductWizard?.SecondaryColor ?? "#1D74F5"};
			--wizard-font-color-primary: ${this.FrontendDesign?.ProductWizard?.FontColorPrimary ?? "#fff"};
			--wizard-font-color-secondary: ${this.FrontendDesign?.ProductWizard?.FontColorSecondary ?? "#000"};

			--wizard-background-color-list-selection: ${this.FrontendDesign?.ProductWizard?.BackgroundColorListSelection ?? "#fff"};
			--wizard-font-color-button: ${this.FrontendDesign?.ProductWizard?.FontColorButton ?? "#fff"};
		}`);

		if(this.CompanyQuizConfig.Languages?.length === 1 && this.CompanyQuizConfig.Languages){
			this.setLanguage(this.CompanyQuizConfig.Languages[0] as Languages);
			this._selectedLanguage = this.CompanyQuizConfig.Languages[0] as Languages;
		}
		else
			this.DisplayedTexts = new DisplayedTexts(this);

		this.Quiz.QuestionIdHistory.push(this.Quiz.Questions[0].Id); // Start with first Question
		if (this.CompanyQuizConfig.ChatbotMode?.QuizOnlyMode && (this.CompanyQuizConfig.UrlToBlockFeatureModeOn === window.location.href || this.CompanyQuizConfig.UrlToBlockFeatureModeOn === '*')) {
			this.hideScrollbar();
			this._isQuizClosed = false;
			await Router.push("/question/" + this.Quiz.getCurrentQuestion().Id);
		} else {
			this.showScrollbar();
			this._isQuizClosed = true;
			await Router.push("/");
		}

		return new OperationResult<string>({ Success: true, Data: this.CompanyCode });
	}

	async loadSavedSessionAsync(matchInfo: string | undefined = undefined): Promise<void> {
		const url = new URL(window.location.href);
		let sessionId = url.searchParams.get("id");
		let sessionHash = url.searchParams.get("hash");
		const companyCode = url.searchParams.get("companyCode");
		const shelfNumberToNavigateTo = url.searchParams.get("shelfNumber"); // If QR-Code of navMap is scanned, open the map
		const showRestartButton = url.searchParams.get("showRestartButton") === "true" ?? undefined;
		const selectedLanguage = url.searchParams.get("language");

		if((!sessionId || !sessionHash) && matchInfo)
		{
			const url = matchInfo;
			const urlObj = new URL(url);
			const searchParams = urlObj.searchParams;

			sessionId = searchParams.get("id");
			sessionHash = searchParams.get("hash");
		}

		const localMatching = localStorage.getItem("match");
		if (localMatching) {
			this.Match = JSON.parse(localMatching);
			this._isQuizClosed = window.location.hash.endsWith("#/result") ? false : true;
		}

		if ((!sessionId || !sessionHash) && !this.Match)
			return;

		if (companyCode) {
			this.CompanyCode = companyCode;
			localStorage.setItem("companyCode", this.CompanyCode);
		}

		if (shelfNumberToNavigateTo) {
			this.CompanyQuizConfig.ResultButtonAction =
				ResultButtonAction.OPENNAVIGATIONMAP;
		}

		if (sessionId && sessionHash) {
			const loadedMatching = await QuizService.getSavedSessionMatch(sessionId, sessionHash);
			this.Match = loadedMatching.match;
			localStorage.setItem("match", JSON.stringify(this.Match))
			localStorage.setItem("sessionId", sessionId);
			localStorage.setItem("sessionHash", sessionHash);

			if(this.CompanyQuizConfig.MatchingCallbackActive){
				DispatchEvent("matchCallback", {
					// tasteType: this.Match.TasteType.Name,
					// matchUrl: this.Match.QrUrl + `&companyCode=${this.CompanyCode}&language=${this._selectedLanguage}`
				});
			}

			if (loadedMatching.companyCode) {
				this.CompanyCode = loadedMatching.companyCode;
				localStorage.setItem("companyCode", this.CompanyCode);
			}

			this.CompanyQuizConfig.ShowRestartButton =
				loadedMatching.match?.CompanyConfig?.ShowRestartButton ?? showRestartButton; // When Info is provided in URL, it should be ranked higher than the server response
		} else this.CompanyCode = localStorage.getItem("companyCode") ?? undefined;

		if (!this.CompanyCode && localStorage.getItem("companyCode"))
			this.CompanyCode = localStorage.getItem("companyCode") ?? undefined;
		if (!this.CompanyCode)
			return;
		const loadedCompany = await QuizService.getQuiz(this.CompanyCode);

		if (!loadedCompany.Success || !loadedCompany.Data)
			return;

		const quizOfLoadedMatching = Company.createFromApiResponse(
			loadedCompany.Data,
			this.CompanyCode
		); // TODO: remove redundance
		// this.Quiz = quizOfLoadedMatching.Quiz;
		this.FrontendDesign = quizOfLoadedMatching.FrontendDesign;
		this.CompanyQuizConfig = quizOfLoadedMatching.CompanyQuizConfig;
		this.FloorPlan = quizOfLoadedMatching.FloorPlan;
		this.Token = quizOfLoadedMatching.Token;

		this.FirstLevelSupport = quizOfLoadedMatching.FirstLevelSupport;

		this._isCompanyLoaded = true;

		if (selectedLanguage && Languages[selectedLanguage])
			this.setLanguage(Languages[selectedLanguage]);

		if (loadedCompany.Data.translations) {
			this._baseComponentTranslations = loadedCompany.Data.translations;
			this._baseComponentTranslations = Company.filterBasicComponentsForTranslation(
				this._baseComponentTranslations,
				this._selectedLanguage
			);
		}

		if(this.FrontendDesign?.PrimaryFontFamily)
			{
				AddToStyling(`
					@font-face {
						font-family: 'PrimaryFontFamily';
						src: url('${this.FrontendDesign?.PrimaryFontFamily}') format('opentype');
						font-weight: normal;
						font-style: normal;
				}`)
			}
	
			if(this.FrontendDesign?.SecondaryFontFamily)
			{
				AddToStyling(`
					@font-face {
						font-family: 'SecondaryFontFamily';
						src: url('${this.FrontendDesign?.SecondaryFontFamily}') format('opentype');
						font-weight: normal;
						font-style: normal;
				}`)
			}

		AddToStyling(`*{
			--primary-color: ${this.FrontendDesign?.PrimaryColor ?? "#3d3b8e"};
			--secondary-color: ${this.FrontendDesign?.SecondaryColor ?? "#65648a"};
			--background-color: ${this.FrontendDesign?.BackgroundColor ?? "#ffffff"};
			--secondary-color-light: ${this.FrontendDesign?.SecondaryLightColor ?? "#e1e1da"};
			--primary-font-color: ${this.FrontendDesign?.PrimaryFontColor ?? "#333333"};
			--secondary-font-color: ${this.FrontendDesign?.SecondaryFontColor ?? "#65648a"};
			--disabled-button-background-color:  ${
				this.FrontendDesign?.DisabledButtonBackgroundColor ?? "#ccc"
			};
			--disabled-button-font-color:  ${this.FrontendDesign?.DisabledButtonFontColor ?? "#fff"};
			--progress-bar-background-color:  ${this.FrontendDesign?.ProgressBarBackgroundColor ?? "#65648a"};
			--fancy-button-stop-color:  ${this.FrontendDesign?.FancyButtonWaveEndColor ?? "#65648a"};
			--stepless-slider-background-color:  ${
				this.FrontendDesign?.SteplessSliderBackgroundColor ?? "#65648a"
			};
			--primary-font-family: ${this.FrontendDesign?.PrimaryFontFamily ?? "Zilla Slab"};
			--secondary-font-family: ${this.FrontendDesign?.SecondaryFontFamily ?? "Open Sans"};
			--primary-font-weight: ${this.FrontendDesign?.PrimaryFontWeight ?? "bold"};
			--secondary-font-weight: ${this.FrontendDesign?.SecondaryFontWeight ?? "400"};
			--slider-thumb: ${
				this.FrontendDesign?.SliderThumbImage ??
				'url("~@/assets/img/slider_button.svg")'
			}";

			--wizard-first-color-wave: ${this.FrontendDesign?.ProductWizard?.FirstColorWave ?? "#1C1027"};
			--wizard-second-color-wave: ${this.FrontendDesign?.ProductWizard?.SecondColorWave ?? "#90173E"};
			--wizard-primary-color: ${this.FrontendDesign?.ProductWizard?.PrimaryColor ?? "#7B163A"};
			--wizard-secondary-color: ${this.FrontendDesign?.ProductWizard?.SecondaryColor ?? "#1D74F5"};
			--wizard-font-color-primary: ${this.FrontendDesign?.ProductWizard?.FontColorPrimary ?? "#fff"};
			--wizard-font-color-secondary: ${this.FrontendDesign?.ProductWizard?.FontColorSecondary ?? "#000"};
		}`);

		// TODO: check bottleClickAction
		if ((sessionHash && sessionId) || window.location.hash.endsWith("#/result")) {
			const ResultQuestion: Question = {
				Id: 1,
				Type: QuestionType.ProfileChoice,
				Position: 1,
				Text: "",
				Headline: "",
				FrontendSettings: shelfNumberToNavigateTo
					? [{ shelfNumberToNavigate: shelfNumberToNavigateTo }]
					: ([] as QuestionFrontendSetting[]),
				Answers: [] as Answer[],
			} as Question;

			this.Quiz = new Quiz({
				Name: "Loaded Session",
				Questions: [ResultQuestion] as Question[],
				QuestionIdHistory: [ResultQuestion.Id] as number[],
				SelectedAnswers: [] as AnswerForMatching[],
				CurrentSelectedAnswer: undefined,
			} as Quiz);

			this._isSavedSessionLoaded = true;


			this._isCompanyLoaded = true;

			this._isQuizClosed = false;
			this.hideScrollbar();
			await Router.push("/result");
		} else{
			this.loadCompanyAsync(this.CompanyCode, true)
		}
	}

	onAnswerClicked(answer?: { answerId: number; answerValue: string | number } | null): void {
		if (!answer) {
			this.Quiz.CurrentSelectedAnswer = undefined;
			return;
		}

		this.Quiz.CurrentSelectedAnswer = new Answer({
			Id: answer.answerId,
			Value: answer.answerValue ?? null,
		});
	}

	async setAnswerAsync(
		selectedAnswer?: { answerId: number; answerValue: string | number } | null,
		fastClicked = false
	): Promise<void> {
		const selectedAnswerLocal = selectedAnswer
			? new Answer({ Id: selectedAnswer.answerId, Value: selectedAnswer.answerValue })
			: (this.Quiz.CurrentSelectedAnswer as Answer);
		this.Quiz.CurrentSelectedAnswer = undefined;

		if (!selectedAnswerLocal) return;

		const answer = this.Quiz.getAnswerById(selectedAnswerLocal.Id);

		// If Answer has QuestionIdToDrop remove that from QuizFlow
		if(answer.QuestionIdsToDrop && answer.QuestionIdsToDrop.length > 0){
			// add QuestionIdsToDrop array to QuestionsToIgnore
			this.Quiz.QuestionsToIgnore = this.Quiz.QuestionsToIgnore.concat(answer.QuestionIdsToDrop);
		}


		if (answer.NextQuiz) {
			// this.quizId = null; // TODO: Check if needed
			this.Quiz = {} as Quiz;

			// If Answer has next_quiz, open QuizId
			await this.loadCompanyAsync(this.CompanyCode ?? "", true, answer.NextQuiz); // TODO: Check if empty string can be a problem
		}

		let nextQuestion = answer.NextQuestion
			? this.Quiz.getQuestionById(answer.NextQuestion as number)
			: null;

		

		// if answer was clicked fast and fast mode is active
		if(!fastClicked && this.Quiz.getCurrentQuestion().QuestionSpeedMode){
			nextQuestion = this.Quiz.getQuestionById(this.Quiz.getCurrentQuestion().NextQuestion as number);

		}

		// If next question just send current answer
		if (nextQuestion?.Id) {
			
			// If QuestionsToIgnore has values, check if question needs to be skipped
			for (let i = 0; i < this.Quiz.QuestionsToIgnore.length && nextQuestion; i++) {
				if (this.Quiz.QuestionsToIgnore.find(el => nextQuestion?.Id === el)) {
					// Get Question after the one to ignore
					const nextQuestionAfterBlocked = this.Quiz.getQuestionById(nextQuestion.NextQuestion || nextQuestion.Answers[0].NextQuestion as number);
					nextQuestion = nextQuestionAfterBlocked;
				} else {
					break;
				}
			}
			
		
			// Dont await async request to do that in background
			QuizService.sendAnswer(
				this.Quiz.getCurrentQuestion().Id,
				selectedAnswerLocal.Value,
				answer,
				this.Quiz.getCurrentQuestion()
			);

			this.Quiz.SelectedAnswers.push(
				new AnswerForMatching({
					AnswerId: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Id,
					AnswerValue: selectedAnswerLocal.Value?.toString(),
					AnswerScore: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Score,
					QuestionId: this.Quiz.getCurrentQuestion().Id,
				})
			);
			this.Quiz.QuestionIdHistory.push(nextQuestion?.Id);

			if (this.Quiz.getQuestionById(nextQuestion?.Id).Type === QuestionType.ProfileChoice)
				await Router.push("/result");
			else await Router.push("/question/" + answer.NextQuestion);

			// Autoredirect? aka Shopify: skip EmailChoice
			if (nextQuestion?.Type === QuestionType.EmailChoice && this._autoRedirect) {
				this._isEmailQuestionProvided = true;
				const nextQuestionId = nextQuestion.Answers[0].NextQuestion as number;
				this.Quiz.QuestionIdHistory.push(nextQuestionId);
				nextQuestion = answer.NextQuestion
					? this.Quiz.getQuestionById(nextQuestionId)
					: null;
			}

			if (window.innerWidth <= 850 || window.innerWidth >= 2000) {
				process.env.NODE_ENV == "development"
					? window.document.getElementsByClassName("tt_wrapper")[0].scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
					})
					: window.document
							.getElementById("ap-productfinder")
							?.shadowRoot?.querySelector(".tt_wrapper")
							?.scrollIntoView({
								behavior: "smooth",
								block: "start",
								inline: "nearest",
							});
			}
		} else if (this.Quiz.getCurrentQuestion().Type === QuestionType.ProfileChoice) {
			QuizService.sendAnswer(
				this.Quiz.getCurrentQuestion().Id,
				selectedAnswerLocal.Value,
				selectedAnswerLocal,
				this.Quiz.getCurrentQuestion()
			);

			// Send Matchingdata again with email data from profileChoice
			this.Quiz.SelectedAnswers.push(
				new AnswerForMatching({
					AnswerId: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Id,
					AnswerValue:
						this.Quiz.getAnswerById(selectedAnswerLocal.Id).Value?.toString() ??
						selectedAnswerLocal.Value?.toString(),
					AnswerScore: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Score,
					QuestionId: this.Quiz.getCurrentQuestion().Id,
				})
			);

			await QuizService.getMatch(this.Quiz.SelectedAnswers, this._selectedLanguage);
		}

		if (
			nextQuestion &&
			(nextQuestion.Type === QuestionType.ResultChoice ||
				nextQuestion.Type === QuestionType.ProfileChoice)
		) {
			this.getQuizMatching();
		}
	}

	async getQuizMatching() {
		let gtins;
		if (
			this.CompanyQuizConfig.BTGMode &&
			localStorage.getItem("deviceId") &&
			localStorage.getItem("companyId")
		) {
			// Old Version to get BTG GTINS

			const response = await axios.get(
				`https://weinkiosk.bytheglass.eu/api/companies/${localStorage.getItem(
					"companyId"
				)}/kiosks/${localStorage.getItem("deviceId")}`
			);

			gtins = response.data.taps.map((el: any) => el.product.ean);
		}

		if (
			this.CompanyQuizConfig.BTGMode &&
			localStorage.getItem("BTG_GTINS")
		) {
			// Get BTG GTINS as JSON Array
			gtins = localStorage.getItem("BTG_GTINS")?.split(",");
		}

		const matchingResponse = await QuizService.getMatch(
			this.Quiz.SelectedAnswers,
			this.selectedLanguage,
			gtins ?? undefined
		);
		// Send Answers to API and get Matching

		matchingResponse.CompanyConfig = this.CompanyQuizConfig;

		this.Match = matchingResponse;

		// Save Matchinresponse in LocalStorage to not forget it
		localStorage.setItem("match", JSON.stringify(this.Match));
		localStorage.setItem("sessionId", this.Match.SessionId);
		localStorage.setItem("sessionHash", this.Match.SessionHash);

		if(this.CompanyQuizConfig.MatchingCallbackActive){
			DispatchEvent("matchCallback", {
				// tasteType: this.Match.TasteType.Name,
				// matchUrl: this.Match.QrUrl + `&companyCode=${this.CompanyCode}&language=${this._selectedLanguage}`
			});
		}

		if(this.CompanyQuizConfig.SetMatchToUrl)
		{
			const smalifiedMatch = {
				// TasteType: this.Match.TasteType,
				Products: this.Match.Products.slice(0,5).map(el => {

					const productToUse = {
						Id: el.Id,
						Name: el.Name,
						GTIN: el.GTIN,
						Country: el.Country,
						Region: el.Region,
						TasteMatch: el.TasteMatch,
						Handle: el.Handle,
						PictureFront: el.PictureFront,

					}

					return {};
				}),
			}

			const url = window.location.href;
			const newUrl = url + "?match=" + JSON.stringify(smalifiedMatch);
			window.history.pushState({path: newUrl}, '', newUrl);
		}


		if (this._autoRedirect) {
			// const qrUrl = new URL(matchingResponse.QrUrl);
			const currentUrl = window.location.href.split("#/")[0].replace(/\/$/, "");
			// window.location.href = currentUrl + "/" + qrUrl.searchParams.get("id");
		}
	}

	static filterBasicComponentsForTranslation(
		translations: Translations,
		selectedLanguage: string
	): Translations {
		const translationsToReturn = { ...translations };

		for (const [key, value] of Object.entries(translations)) {
			translationsToReturn[key].text = value[selectedLanguage];
		}

		return translationsToReturn;
	}

	getLanguageQuestion(): Question {
		if (!this.CompanyQuizConfig.Languages) return {} as Question;

		const languageAnswers = this.CompanyQuizConfig.Languages;

		const languageQuestion = new Question({
			Id: 1,
			Type: QuestionType.SinglePictureChoice,
			Position: 0,
			Text: "Sprache auswählen / Select your language",
			Headline: null,
			Answers: languageAnswers.map((language, index) => {
				return {
					Id: index,
					Text: language.toUpperCase(),
					Score: index,
					Picture: "@/assets/img/" + language + ".svg",

					NextQuestion: 0,
					NextQuiz: 0,
					Selected: false,
					Value: language,
				} as Answer;
			}),
			Explanation: null,
			ExplanationEn: null,
			FrontendSettings: [] as QuestionFrontendSetting[],
			Picture: null,
		});

		return languageQuestion;
	}

	setLanguage(selectedLanguage = Languages.DE): void {
		this._selectedLanguage = selectedLanguage;
		this.Quiz = QuizService.filterQuizForLanguage(this.Quiz, this._selectedLanguage);

		// Filter BaseComponentTranslations for selected Language
		for (const [key, value] of Object.entries(this._baseComponentTranslations)) {
			this._baseComponentTranslations[key].text = value[this._selectedLanguage];
		}

		this._languageWasSelected = true;

		this.DisplayedTexts = new DisplayedTexts(this);
	}

	isProductAvailable(product: Product): boolean {
		if (
			this.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.OPENNAVIGATIONMAP ||
			this.CompanyQuizConfig.ResultButtonAction === ResultButtonAction.SHOWQRCODE ||
			this.CompanyQuizConfig.ResultButtonAction === ResultButtonAction.STARTTASTING ||
			this.CompanyQuizConfig.ResultButtonAction === ResultButtonAction.EXPLOREMODE
		) {
			return true;
		} else if (
			this.CompanyQuizConfig.ResultButtonAction === ResultButtonAction.SAVEMODE
		) {
			return true;
		} else return !!product.Handle;
	}

	async startQuiz(hardReset?: boolean, quizId?: number) {
		if (hardReset) localStorage.removeItem("match");

		await this.loadCompanyAsync(this.CompanyCode!, hardReset, quizId);

		this._isQuizClosed = false;

		this.hideScrollbar();

		if (this.Match && !hardReset) this.openResult();
		else await Router.push("/question/" + this.Quiz.getCurrentQuestion().Id);
	}

	async openResult() {
		this._isQuizClosed = false;
		this.hideScrollbar();

		// Set resultquestion as current question
		if (this.Quiz.Questions.find((q) => q.Type === QuestionType.ProfileChoice))
			this.Quiz.QuestionIdHistory.push(
				this.Quiz.Questions.find((q) => q.Type === QuestionType.ProfileChoice)!.Id
			);
		else {
			this.Quiz.Questions.push({
				Type: QuestionType.ProfileChoice,
				Id: this.Quiz.Questions.length + 1 ?? 1,
			} as Question);
			this.Quiz.QuestionIdHistory.push(this.Quiz.Questions.length + 1 ?? 1);
		}

		await Router.push("/result");
	}

	hideScrollbar() {
		if (!this.CompanyQuizConfig.ChatbotMode?.QuizOnlyMode)
			window.document.querySelector("html")!.style.cssText = `
			/* Hide scrollbar for Webkit (Safari, Chrome) */
			-webkit-overflow-scrolling: touch; /* Enable momentum scrolling */
			-webkit-appearance: none; /* Remove default appearance */
			/* Hide scrollbar for Firefox */
			overflow: -moz-scrollbars-none;
			/* Hide scrollbar for Edge and Internet Explorer */
			overflow-y: -ms-autohiding-scrollbar;
			/* Hide scrollbar for all other browsers */
			overflow: hidden;
		`;
	}

	showScrollbar() {
		if (!this.CompanyQuizConfig.ChatbotMode?.QuizOnlyMode) {
			const html = document.querySelector("html")!;
			html.style.setProperty("-webkit-overflow-scrolling", "");
			html.style.webkitAppearance = "";
			html.style.overflow = "";
			html.style.overflowY = "";
		}
	}

	closeQuiz() {
		this._isQuizClosed = true;

		this.showScrollbar();

		Router.push("/");
	}

	async restartQuiz() {
		// TODO: Fix to reload quiz also in Chatbotmode
		localStorage.removeItem("match");
		const runInFeature = !this.CompanyQuizConfig.ChatbotMode?.QuizOnlyMode || this.CompanyQuizConfig.SuperSearchActive;
		const homepageMode = this.homepageMode;
		const transparentMode = this.CompanyQuizConfig.TransparentMode;

		this.Match = undefined;
		this.Quiz.SelectedAnswers = [];
		this.Quiz.QuestionIdHistory = [];
		this.Quiz.QuestionIdHistory.push(this.Quiz.Questions[0].Id);
		await this.loadCompanyAsync(this.CompanyCode!, true);

		this.isQuizClosed = false;

		if (this.CompanyQuizConfig.ChatbotMode)
			this.CompanyQuizConfig.ChatbotMode.QuizOnlyMode = runInFeature;

		this.homepageMode = homepageMode;
		this.CompanyQuizConfig.TransparentMode = transparentMode;

		await Router.push("/question/" + this.Quiz.Questions[0].Id);

		// if (this.CompanyQuizConfig.ChatbotMode?.QuizOnlyMode) {
		// 	this.showScrollbar();
		// 	this._isQuizClosed = true;
		// }

		// 	window.location.href =
		// 	location.protocol + "//" + location.host + "/#/" + this.CompanyCode;
		// 	window.location.reload();
	}

	loadImagesToCache() {
		// preload images when assetPath is set
		const AnswerImages = this.Quiz.Questions.map(({ Answers }) => Answers);

		const tempArr: Answer[] = [];
		tempArr.concat
			.apply([], AnswerImages)
			.filter(({ Picture }) => Picture)
			.map(({ Picture }) => Picture)
			.map((Picture) => {
				const image = new Image();
				image.src = Picture ?? "";
			});

		// Load Action Buttons Images to Cache
		const actionButtons = this.FrontendDesign?.ActionButtons?.map((el) => el.image);

		if (actionButtons) {
			actionButtons.forEach((image) => {
				const img = new Image();
				img.src = image;
			});
		}
	}

	// Old BTG Version

	async getBtgGtins(){
		let gtins;
		if (
			this.CompanyQuizConfig.BTGMode &&
			localStorage.getItem("deviceId") &&
			localStorage.getItem("companyId")
		) {
			const response = await axios.get(
				`https://weinkiosk.bytheglass.eu/api/companies/${localStorage.getItem(
					"companyId"
				)}/kiosks/${localStorage.getItem("deviceId")}`
			);

			gtins = response.data.taps.map((el: any) => el.product.ean);
		}

		if(this.CompanyQuizConfig.BTGMode && localStorage.getItem("BTG_GTINS"))
		{
			// Get BTG GTINS as JSON Array
			gtins = localStorage.getItem("BTG_GTINS")?.split(",");
		}

		return gtins;
	}

	// Getter and setters for private properties

	// When Quiz get's setted, check for translation
	set Quiz(quiz: Quiz) {
		if(!this._languageWasSelected)
			this._selectedLanguage = localStorage.getItem("language") as Languages;

		if(quiz && quiz.Questions && quiz.Questions.length > 0)
			this._quiz = Quiz.filterQuizForLanguage(quiz, this._selectedLanguage);
	}

	get Quiz(): Quiz {
		return this._quiz;
	}

	get isCompanyLoaded(): boolean {
		return this._isCompanyLoaded;
	}

	get homepageMode(): boolean {
		return this._homepageMode;
	}

	set homepageMode(val) {
		this._homepageMode = val;
	}

	get baseComponentTranslations(): Translations {
		return this._baseComponentTranslations;
	}

	get isSavedSessionLoaded(): boolean {
		return this._isSavedSessionLoaded;
	}

	get selectedLanguage(): Languages {
		return this._selectedLanguage;
	}

	get languageWasSelected(): boolean {
		return this._languageWasSelected;
	}

	// get isEmailQuestionProvided(): boolean {
	// 	if (this._isEmailQuestionProvided) return true;

	// 	if (this._isSavedSessionLoaded) {
	// 		// if Session is loaded and E-Mail of user is in DB
	// 		return this.Match?.ProvidedContact ?? false;
	// 	} else {
	// 		return this.Quiz.Questions
	// 			? this.Quiz.Questions.some(
	// 					(el) =>
	// 						el.Type === QuestionType.EmailChoice &&
	// 						this.Quiz.QuestionIdHistory.indexOf(el.Id) !== -1
	// 			)
	// 			: false;
	// 	}
	// }

	emailFormSend(isProvided: boolean) {
		this._isEmailQuestionProvided = isProvided;
	}

	get isIGetNowModeActive(): boolean {
		return !!window["__IGNDL"];
	}

	get isQuizClosed(): boolean {
		if (
			this.CompanyQuizConfig.UrlToBlockFeatureModeOn &&
			window.location.href.includes(this.CompanyQuizConfig.UrlToBlockFeatureModeOn)
		)
			return false;

		return this._isQuizClosed;
	}

	set isQuizClosed(val: boolean) {
		this._isQuizClosed = val;
	}
}
